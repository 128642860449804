<template>
  <el-table ref="multipleTable" v-loading="loading" :data="tableData" border style="width: 100%">
    <el-table-column label="序号" min-width="80">
      <template slot-scope="scope">
        <span>{{ total - scope.$index - (form.pageNumber - 1) * form.pageSize }}</span>
      </template>
    </el-table-column>
    <el-table-column prop="userName" label="员工姓名" min-width="120"> </el-table-column>
    <el-table-column prop="userId" label="员工ID" min-width="120"> </el-table-column>
    <el-table-column prop="departmentName" label="所在部门" min-width="180"> </el-table-column>
    <el-table-column prop="postNames" label="岗位" min-width="150"> </el-table-column>
    <el-table-column prop="levelName" label="等级" min-width="80"> </el-table-column>
    <el-table-column prop="phoneNumber" label="联系电话" min-width="120"> </el-table-column>
    <el-table-column prop="email" label="邮箱" min-width="200"> </el-table-column>
    <el-table-column prop="slogan" label="个人签名" min-width="200"> </el-table-column>
    <el-table-column prop="sharing" label="分享量" min-width="100"> </el-table-column>
    <el-table-column prop="look" label="浏览量" min-width="100"> </el-table-column>
    <el-table-column prop="love" label="点赞量" min-width="100"> </el-table-column>
    <el-table-column prop="isDisable" label="状态" min-width="100">
      <template slot-scope="scope">
        <span>{{ scope.row.isDisable == 1 ? '启用' : '禁用' }}</span>
      </template>
    </el-table-column>
    <el-table-column prop="updateTime" label="更新时间" min-width="160"> </el-table-column>
    <el-table-column label="操作" min-width="120">
      <template slot-scope="scope">
        <el-button type="text" size="small" @click="edit(scope.row)">编辑</el-button>
        <el-button type="text" size="small" @click="del(scope.row)">删除</el-button>
        <el-button type="text" size="small" @click="look(scope.row)">预览</el-button>
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
export default {
  props: {
    tableData: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
    form: {
      type: Object,
      default: () => {},
    },
    total: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      selectData: [],
    }
  },
  methods: {
    getArrDifference(arr1, arr2) {
      return arr1.concat(arr2).filter(function (v, i, arr) {
        return arr.indexOf(v) === arr.lastIndexOf(v)
      })
    },
    toggleSelection(rows) {
      if (rows) {
        this.$refs.multipleTable.toggleAllSelection()
      } else {
        this.$refs.multipleTable.clearSelection()
        // if (this.selectData.length > 0) {
        //   console.log(this.getArrDifference(this.tableData, this.selectData));
        //   this.getArrDifference(this.tableData, this.selectData).forEach(
        //     (row) => {
        //       this.$refs.multipleTable.toggleRowSelection(row, true);
        //     }
        //   );
        //   this.selectData.forEach((row) => {
        //     this.$refs.multipleTable.toggleRowSelection(row, false);
        //   });
        // }
      }
    },
    edit(row) {
      this.$emit('edit', row)
    },
    del(row) {
      this.$emit('del', row)
    },
    look(row) {
      this.$emit('look', row)
    },
  },
}
</script>

<style lang="less" scoped></style>
