<template>
  <div class="header">
    <el-form :inline="true" :model="form" class="demo-form-inline">
      <!-- <el-form-item label="部门">
        <el-select
          size="small"
          v-model="form.departmentId"
          placeholder="请选择部门"
        >
          <el-option
            v-for="(n, i) in departmentList"
            :key="i"
            :label="n.departmentName"
            :value="n.departmentId"
          ></el-option>
        </el-select>
      </el-form-item> -->
      <el-form-item label="部门" class="fa">
        <el-input
          v-model="form.departmentName"
          v-clickoutside="inpClick"
          size="small"
          :placeholder="!isShow ? '请选择部门' : flag"
          :suffix-icon="!isShow ? 'el-icon-arrow-down' : 'el-icon-arrow-up'"
          @focus="inpFocus"
        >
        </el-input>
        <tree
          v-show="isShow"
          :value="form.departmentName"
          :is-show="isShow"
          :flag="flag"
          class="tree"
          @ok="selectOk"
        />
      </el-form-item>
      <!--       
      <el-form-item label="员工姓名">
        <el-select size="small" v-model="form.userId" placeholder="请选择员工">
          <el-option
            v-for="(n, i) in platformList"
            :key="i"
            :label="n.userName"
            :value="n.userId"
          ></el-option>
        </el-select>
      </el-form-item> -->
      <el-form-item label="员工ID">
        <el-input
          v-model="form.userId"
          size="small"
          style="width: 215px"
          placeholder="请输入"
        ></el-input>
      </el-form-item>
      <el-form-item label="员工姓名">
        <el-input
          v-model="form.userName"
          size="small"
          style="width: 215px"
          placeholder="请输入"
        ></el-input>
      </el-form-item>
      <el-form-item label="更新时间">
        <el-date-picker
          v-model="form.time"
          size="small"
          type="datetimerange"
          range-separator="至"
          style="width: 340px"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" size="small" @click="onSubmit">查询</el-button>
        <el-button type="primary" size="small" @click="reset">重置</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import tree from '@/views/promotion/promotionStatistics/components/tree.vue'
import { clickoutside } from '@/utils/way'

import moment from 'moment'
export default {
  name: 'PageHeader',
  components: {
    tree,
  },
  directives: { clickoutside },
  props: {
    platformList: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      form: {
        departmentName: '',
      },
      fold: false,
      isShow: false,
      flag: '',
    }
  },
  methods: {
    inpFocus() {
      this.isShow = true
      this.form.departmentId = ''
      this.form.departmentName = ''
    },
    inpClick() {
      this.isShow = false
      if (this.flag) this.form.departmentName = this.flag
    },
    selectOk(data) {
      this.form.departmentId = data.departmentId
      this.form.departmentName = data.departmentName
      this.flag = data.departmentName
      this.isShow = false
    },
    onSubmit() {
      const data = { ...this.form }
      if (data.time) {
        data.updateTimeStart = moment(data.time[0]).format('YYYY-MM-DD HH:mm:ss')
        data.updateTimeEnd = moment(data.time[1]).format('YYYY-MM-DD HH:mm:ss')
      }
      delete data.time
      this.$emit('search', data)
    },
    reset() {
      this.form = {
        departmentName: '',
      }
      this.flag = ''
      this.$emit('search', this.form)
    },
    checkFold() {
      this.fold = !this.fold
    },
  },
}
</script>
<style lang="less" scoped>
.fa {
  position: relative;
  .tree {
    position: absolute;
  }
}
</style>
