<template>
  <div>
    <page-header :platform-list="platformList" @search="search" />
    <div class="table">
      <div class="btn">
        <el-button type="primary" size="small" @click="add">新增名片</el-button>
      </div>
      <Table
        ref="table"
        :table-data="tableData"
        :loading="loading"
        :form="form"
        :total="total"
        @handleSelectionChange="handleSelectionChange"
        @edit="edit"
        @del="del"
        @look="look"
      />
      <Pages :total="total" :current-page="form.pageNumber" @handleSizeChange="handleSizeChange" @handleCurrentChange="handleCurrentChange" />
    </div>
    <el-dialog :title="isAdd ? '新增名片' : '编辑名片'" :visible.sync="dialogFormVisible" width="500px" @close="cancel">
      <el-form ref="ruleForm" :model="ruleForm" :rules="rules" label-width="120px" class="demo-ruleForm" size="small">
        <!-- <el-form-item label="所在部门" prop="departmentId">
          <el-select
            filterable
            @change="departmentChange"
            v-model="ruleForm.departmentId"
            placeholder="请选择部门"
          >
            <el-option
              v-for="(n, i) in departmentList"
              :key="i"
              :label="n.departmentName"
              :value="n.departmentId"
            ></el-option>
          </el-select>
        </el-form-item> -->
        <el-form-item label="所在部门" class="fa" prop="departmentName">
          <el-input
            v-model="ruleForm.departmentName"
            v-clickoutside="inpClick"
            size="small"
            style="width: 215px"
            :placeholder="!isShow ? '请选择部门' : flag"
            :suffix-icon="!isShow ? 'el-icon-arrow-down' : 'el-icon-arrow-up'"
            @focus="inpFocus"
          >
          </el-input>
          <tree v-show="isShow" :id="ruleForm.departmentId" :value="ruleForm.departmentName" :is-show="isShow" :flag="flag" class="tree" @ok="selectOk" />
        </el-form-item>
        <el-form-item label="岗位名称" prop="postId">
          <el-select v-model="ruleForm.postId" style="width: 215px" filterable placeholder="请选择岗位" @change="postChange">
            <el-option v-for="(n, i) in postList" :key="i" :label="n.postName" :value="n.postId"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="员工姓名" prop="userId">
          <el-select v-model="ruleForm.userId" style="width: 215px" filterable placeholder="请选择员工" @change="userNameChange">
            <el-option v-for="(n, i) in platformList" :key="i" :label="n.userName" :value="n.userId"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="联系电话" prop="phoneNumber">
          <el-input v-model="ruleForm.phoneNumber" size="mini" style="width: 215px" placeholder="自动同步员工信息，可修改"></el-input>
        </el-form-item>
        <!-- <el-form-item label="企业微信" prop="enterpriseWechat">
          <el-input
            size="mini"
            readonly
            v-model="ruleForm.enterpriseWechat"
            style="width: 215px"
            placeholder="自动同步员工信息，不可修改"
          ></el-input>
        </el-form-item> -->
        <el-form-item label="邮箱">
          <el-input v-model="ruleForm.email" size="mini" style="width: 215px" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="地址">
          <el-input v-model="ruleForm.address" size="mini" style="width: 215px" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="等级">
          <el-select v-model="ruleForm.levelId" style="width: 215px" placeholder="请选择等级">
            <el-option v-for="(n, i) in levelList" :key="i" :label="n.dictionaryName" :value="n.dictionaryValue"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="个性签名">
          <el-input v-model="ruleForm.slogan" style="width: 300px" type="textarea" :rows="8" maxlength="500" show-word-limit placeholder="请输入"> </el-input>
        </el-form-item>
        <el-form-item label="启用状态" prop="isDisable">
          <el-radio-group v-model="ruleForm.isDisable">
            <el-radio :label="1">启用</el-radio>
            <el-radio :label="0">禁用</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="cancel">取 消</el-button>
        <el-button size="small" type="primary" @click="ok">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog :visible.sync="lookVisible" width="416px">
      <iframe width="375px" height="640px" :src="lookUrl" frameborder="0"></iframe>
    </el-dialog>
  </div>
</template>

<script>
import tree from '@/views/promotion/promotionStatistics/components/tree.vue'
import pageHeader from './header'
import Table from './table'
import Pages from '@/components/pages'
import md5 from 'js-md5'
import { clickoutside } from '@/utils/way'

import {
  businessCard,
  businessCardsave,
  queryChildsById,
  // departmentTree,
  platformPageList,
  getPostPage,
  businessCardDel,
} from '@/api'
export default {
  components: {
    pageHeader,
    Table,
    Pages,
    tree,
  },
  directives: { clickoutside },

  data() {
    return {
      tableData: [],
      lookVisible: false,
      total: 0,
      multipleSelection: [],
      form: {
        data: {},
        pageNumber: 1,
        pageSize: 10,
      },
      isAdd: false,
      ruleForm: {
        departmentName: '',
        isDisable: 1,
        departmentId: '',
        postId: '',
        phoneNumber: '',
        // enterpriseWechat: "",
        userName: '',
        userId: '',
      },

      loading: false,
      dialogFormVisible: false,
      rules: {
        departmentName: [{ required: true, message: '请选择部门', trigger: 'change' }],
        postId: [{ required: true, message: '请选择岗位', trigger: 'change' }],
        userId: [{ required: true, message: '请选择员工', trigger: 'blur' }],
        // enterpriseWechat: [
        //   { required: true, message: "请输入企业微信", trigger: "blur" },
        // ],
        phoneNumber: [{ required: true, message: '请输入电话', trigger: 'blur' }],

        isDisable: [{ required: true, message: '请选择是否启用', trigger: 'change' }],
      },
      levelList: [],
      departmentList: [],
      platformList: [],
      postList: [],
      platform: {
        pageNumber: 1,
        pageSize: 9999,
        data: { isResign: 0 },
      },
      lookUrl: '',
      isShow: false,
      flag: '',
    }
  },
  watch: {
    lookVisible: function (val) {
      if (!val) {
        this.lookUrl = ''
      }
    },
  },
  mounted() {
    this.getTableData()
    queryChildsById({
      data: { key: 'business_card_level' },
    }).then((res) => {
      this.levelList = res.data.childs
    })
    // departmentTree({ data: { isEnable: 0 } }).then((res) => {
    //   this.departmentList = res.data;
    // });

    getPostPage({ pageNumber: 1, pageSize: 9999, data: { isDisable: 0 } }).then((res) => {
      this.postList = res.data.content
    })
  },
  methods: {
    inpFocus() {
      this.isShow = true
      // this.ruleForm.departmentId = "";
      // this.ruleForm.departmentName = "";
    },
    inpClick() {
      this.isShow = false
      if (this.flag) this.ruleForm.departmentName = this.flag
    },
    selectOk(data) {
      this.ruleForm.departmentId = data.departmentId
      this.ruleForm.departmentName = data.departmentName
      this.departmentChange(data.departmentId)
      this.flag = data.departmentName
      this.isShow = false
    },
    platformPageList() {
      platformPageList(this.platform).then((res) => {
        this.platformList = res.data.content
        this.setForm()
      })
    },
    departmentChange(e) {
      this.platform.data.department = [e]
      this.platformList.forEach((v) => {
        if (v.departmentId === e) {
          this.ruleForm.idPath = v.idPath
        }
      })
      this.platformPageList()
    },
    setForm() {
      this.ruleForm.phoneNumber = ''
      // this.ruleForm.enterpriseWechat = "";
      this.ruleForm.userName = ''
      this.ruleForm.userId = ''
    },
    postChange(e) {
      this.platform.data.post = [e]
      // this.setForm();
      this.platformPageList()
    },
    search(data) {
      this.form.pageNumber = 1
      this.form.data = { ...data }
      this.getTableData()
    },
    userNameChange(n) {
      this.platformList.forEach((e) => {
        if (e.userId === n) {
          this.$set(this.ruleForm, 'userName', e.userName)
          // this.$set(this.ruleForm, "enterpriseWechat", e.enterpriseWechat);
          this.$set(this.ruleForm, 'phoneNumber', e.phoneNumber)
        }
      })
    },

    getTableData() {
      this.loading = true
      businessCard(this.form).then((res) => {
        this.loading = false
        this.tableData = res.data.content
        this.total = Number(res.data.total)
      })
    },
    handleNodeClick(data) {
      console.log(data)
    },
    edit(row) {
      this.isAdd = false
      this.dialogFormVisible = true
      const e = { ...row }
      if (e.departmentId.indexOf('[') !== -1) {
        e.departmentId = JSON.parse(e.departmentId)[0]
      }
      e.postId = e.post
      this.platform.data.department = [e.departmentId]
      this.platform.data.post = [e.postId]
      platformPageList(this.platform).then((res) => {
        this.platformList = res.data.content
      })
      e.levelId = Number(e.levelId) === 0 ? '' : e.levelId + ''
      this.ruleForm = { ...e }
      this.flag = e.departmentName
    },
    look(row) {
      this.lookUrl = process.env.VUE_APP_H5_URL + 'shareCard?id=' + row.cardId + '&key=' + md5(row.weChatKey) + '&type=look'
      this.lookVisible = true
    },
    del(row) {
      this.$confirm('确认删除该条数据?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          businessCardDel({
            data: {
              cardId: row.cardId,
            },
          }).then((res) => {
            this.$message({
              type: 'success',
              message: '删除成功!',
            })
            this.getTableData()
          })
        })
        .catch(() => {})
    },
    add() {
      this.isAdd = true
      this.dialogFormVisible = true
    },
    ok() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          const data = { ...this.ruleForm }
          businessCardsave({
            data,
          }).then((res) => {
            this.$message({
              type: 'success',
              message: '操作成功!',
            })
            this.cancel()
            this.getTableData()
          })
        } else {
          return false
        }
      })
    },
    cancel() {
      this.platformList = []
      this.$refs.ruleForm.resetFields()
      this.flag = ''
      this.ruleForm = {
        isDisable: 1,
        departmentName: '',
        departmentId: '',
        postId: '',
        phoneNumber: '',
        // enterpriseWechat: "",
        userName: '',
        userId: '',
      }
      this.dialogFormVisible = false
    },
    toggleSelection(rows) {
      this.$refs.table.toggleSelection(rows)
    },
    handleSizeChange(val) {
      this.form.pageSize = val
      this.form.pageNumber = 1
      this.getTableData()
    },
    handleCurrentChange(val) {
      this.form.pageNumber = val
      this.getTableData()
    },
    handleSelectionChange(val) {
      this.multipleSelection = val
    },
    exportTable() {},
  },
}
</script>
<style lang="less" scoped>
.fa {
  position: relative;
  .tree {
    position: absolute;
  }
}
</style>
