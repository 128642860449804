<template>
  <div>
    <el-tree
      :id="id"
      ref="tree"
      class="filter-tree"
      :data="data"
      :props="defaultProps"
      default-expand-all
      node-key="departmentId"
      :filter-node-method="filterNode"
      @node-click="nodeClick"
    >
    </el-tree>
  </div>
</template>

<script>
import { departmentTree } from '@/api/card.js'

export default {
  props: {
    value: {
      type: [Number, String],
      default: '',
    },
    isShow: {
      type: Boolean,
      default: false,
    },
    flag: {
      type: [Boolean, String],
      default: false,
    },
    id: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      filterText: '',
      data: [],
      defaultProps: {
        children: 'children',
        label: 'departmentName',
      },
    }
  },
  watch: {
    value(val) {
      this.$refs.tree.filter(val)
    },
    isShow(val) {
      if (val) this.$refs.tree.filter('')
      if (val && !this.flag) this.$refs.tree.setCurrentKey(null)
      if (val && this.id) this.$refs.tree.setCurrentKey(this.id)
    },
  },
  mounted() {
    this.departmentTree()
  },
  methods: {
    filterNode(value, data) {
      if (!value) return true
      return data.departmentName.indexOf(value) !== -1
    },
    departmentTree() {
      departmentTree({
        data: {
          isEnable: 0,
        },
      }).then((res) => {
        this.data = res.data
      })
    },
    nodeClick(data) {
      this.$emit('ok', data)
    },
  },
}
</script>

<style lang="less" scoped>
.tree {
  width: 240px;
  max-height: 260px;
  box-sizing: border-box;
  ::v-deep .is-current > .el-tree-node__content > .el-tree-node__label {
    color: #2861e2;
    font-weight: bold;
  }
  .el-tree {
    max-height: 240px;
    overflow-y: scroll;
  }
  .el-tree::-webkit-scrollbar {
    width: 6px;
    height: 10px;
  }
  .el-tree::-webkit-scrollbar-thumb {
    background-color: #ccc;
    border-radius: 50px;
  }
  /*  滚动条轨道. */
  .el-tree::-webkit-scrollbar-track {
    background-color: #fff;
  }
  padding: 10px 2px;
  background-color: #fff;
  z-index: 99;
  box-shadow: 0 2px 12px 0 #ccc;
  border-radius: 3px;
}
</style>
